import { Checkbox, Input } from '@sminex/sminex-uikit';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles.ts';
import { useEffect, useState } from 'react';
import './styles.css';
import { useFetchUsers, IUser } from '@shared/hooks/useFetchUsers';

interface CompaniesStructureProps {
  invitedParticipants: number[];
  setSelectedParticipants: (users: number[]) => void;
  selectedParticipants: number[];
}

interface Participant {
  id: number;
  firstName: string;
  lastName: string;
  avatar: null | string;
}

const CompaniesStructure: React.FC<CompaniesStructureProps> = ({
  setSelectedParticipants,
  invitedParticipants = [],
  selectedParticipants,
}) => {
  const { userData } = useFetchUsers();

  const [inputText, setInputText] = useState('');
  const [filteredData, setFilteredData] = useState<IUser[]>([]);

  useEffect(() => {
    if (userData && userData.data) {
      setFilteredData(userData.data);
    }
  }, [userData]);

  const handleCheck = (participant: Participant) => {
    if (selectedParticipants.includes(participant.id)) {
      setSelectedParticipants(selectedParticipants.filter((el) => el !== participant.id));
      return;
    }
    setSelectedParticipants([...selectedParticipants, participant.id]);
  };

  const statusAllChecked = userData && userData.data.length === invitedParticipants.length;

  const handleAllCheck = () => {
    const newCheckedUsers = userData && userData.data.map((participant: Participant) => participant.id);
    setSelectedParticipants(newCheckedUsers || []);
  };

  const handleInput = (text: string) => {
    setInputText(text);
    const lookingText = text.trim().toLocaleLowerCase();

    const filtered =
      userData &&
      userData.data.filter(
        (participant: Participant) =>
          participant.firstName.trim().toLowerCase().includes(lookingText) ||
          participant.lastName.trim().toLowerCase().includes(lookingText)
      );
    setFilteredData(filtered || []);
  };

  return (
    <div {...stylex.props(styles.container)}>
      <div {...stylex.props(styles.inputWithSelectedParticipant)}>
        <div {...stylex.props(styles.input)}>
          <Input
            type="search"
            status="default"
            placeholder="Поиск"
            value={inputText}
            size="small"
            settings={{ width: '546px' }}
            changeFunction={handleInput}
          />
        </div>
        <div {...stylex.props(styles.participant)}>
          Количество выбранных участников: {[...invitedParticipants, ...selectedParticipants].length}
        </div>
      </div>
      <div {...stylex.props(styles.blockAllList)}>
        <div {...stylex.props(styles.checkboxWithName)}>
          <Checkbox onChange={handleAllCheck} size={'small'} isChecked={statusAllChecked} isIndeterminate={false} />
          <div {...stylex.props(styles.participant)}>Выбрать всех сотрудников</div>
        </div>

        <div className="custom-scroll">
          <div {...stylex.props(styles.allList)}>
            {filteredData.map((participant, index) => (
              <div key={index} {...stylex.props(styles.checkboxWithName)} onClick={() => handleCheck(participant)}>
                <Checkbox
                  onChange={() => {}}
                  size={'small'}
                  isChecked={[...invitedParticipants, ...selectedParticipants].some((el) => el === participant.id)}
                />
                <div {...stylex.props(styles.participant)}>
                  {participant.firstName} {participant.lastName}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompaniesStructure;
